import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import { firestore } from './../firebase-config';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "react-slick";
import { id_firebase, nome_revenda } from '../content-site';

function ImagensLoja(data) {
    
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        accessibility: true
    };

    const imagens = data.images;
    const listImagens = imagens.map((key) => 
        <div key={key}><img className="img-responsive center-block" src={ key } alt={ "Banner "+ nome_revenda } /></div>
    );
    return <Slider {...settings}>{ listImagens }</Slider>;

}

class Inicio extends Component {

    constructor (props){
        super(props);

        this.state = {
            veiculos: [],
            veiculosComplete: [],
            countVeiculos: 0,
            instagramData: [],
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
            revenda: {},
            bannerData: []
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick  = this.handleClick.bind(this);
    }

    componentDidMount(){

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    revenda: doc.data(),
                });
            }else{
                this.setState({
                    revenda: {
                        ativo: 0,
                    }
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(id_firebase).collection("veiculos").where("veremovido", "==", 0).where("vevendido", "==", 0).orderBy("updated_at", "desc")
        .onSnapshot(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                if(document["veexibir_site"] !== false){
                    items.push(document);
                }
            });
            if(this.mounted) {
                this.setState({
                    veiculos: items,
                    veiculosComplete: items,
                    countVeiculos: items.length
                });
            }
        });
        
        firestore.collection("revenda").doc(id_firebase).collection("imagem_destaque").orderBy("created_at", "desc")
        .onSnapshot(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items.push(document);
            });
            if(this.mounted) {
                this.setState({
                    instagramData: items
                });
            }
        });

        firestore.collection("revenda").doc(id_firebase).collection("banner_site").orderBy("created_at", "desc")
        .onSnapshot(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items.push(document.imagem_url);
            });
            if(this.mounted) {
                this.setState({
                    bannerData: items
                });
            }
        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        let filtro = this.state.buscaVeiculo.toLowerCase();
        let tipoVeiculo = this.state.tipoVeiculo;

        let veiculosFilter = this.state.veiculosComplete.filter( function (veiculo) {
            let descricaoVeiculo = veiculo.vefipe_marca.toLowerCase() + " " + veiculo.vefipe_name.toLowerCase();
            if(tipoVeiculo === 'novos'){
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem <= 100;
            }
            if(tipoVeiculo === 'seminovos'){
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem > 100;
            }
            return descricaoVeiculo.includes(filtro);
        });

        this.setState({
            veiculos: veiculosFilter
        });
        
    }

    handleClick(event) {
        this.setState({
            veiculos: this.state.veiculosComplete,
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
        });
    }

    render(){
        const { 
            veiculos, 
            instagramData,
            tipoVeiculo,
            bannerData
        } = this.state;

        if(this.state.revenda.ativo === 0){
            return(
                <div style={{ textAlign: "center" }}>
                    <h1>Site em manutenção.</h1>
                    <h3>Contate o suporte para mais detalhes (suporte@intermedioveiculos.com.br)</h3>
                </div>
            )
        }

        return(
            <div>
                <Cabecalho />
                <div className="row hidden-xs">
                    <div className="col-xs-12">
                        <ImagensLoja images={bannerData} />
                    </div>
                </div>
                <div>
                    <div className="b-items page-responsive-margin">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-3 col-sm-push-9">
                                    <aside className="b-items__aside">
                                        <div className="b-items__aside-main wow zoomInUp formulario-busca-veiculo" data-wow-delay="0.5s">
                                            <ValidatorForm id="contactForm" className="s-form wow zoomInUp" ref="form" onSubmit={this.handleSubmit} >
                                                <div className="b-items__aside-main-body">
                                                    <div className="b-items__aside-main-body-item">
                                                        <label>Encontre seu veículo</label>
                                                        <div>
                                                            <TextValidator
                                                                placeholder="Ex.: Fiat Palio"
                                                                onChange={this.handleChange}
                                                                name="buscaVeiculo"
                                                                value={this.state.buscaVeiculo}
                                                                className="form-ui-bootstrap"
                                                            />
                                                        </div>
                                                        <div>
                                                            <SelectValidator
                                                                value={ tipoVeiculo }
                                                                name="tipoVeiculo"
                                                                className="form-ui-bootstrap select-busca-veiculos"
                                                                onChange={this.handleChange}
                                                                >
                                                                    <MenuItem value="todos">Todos</MenuItem>
                                                                    <MenuItem value="novos">0 Km</MenuItem>
                                                                    <MenuItem value="seminovos">Seminovos</MenuItem>                                                     
                                                            </SelectValidator>
                                                        </div>
                                                    </div>
                                                </div>
                                                <footer className="b-items__aside-main-footer">
                                                    <button type="submit" className="btn m-btn">BUSCAR VEÍCULOS<span className="fa fa-angle-right"></span></button><br />
                                                    <p className="linkLimparBusca" onClick={this.handleClick}>LIMPAR BUSCA</p>
                                                </footer>
                                            </ValidatorForm>
                                        </div>
                                        <br/>
                                        <br/>
                                        {      
                                            instagramData !== undefined && instagramData.length > 0 ? 
                                            ( 
                                                <h2 className="s-title wow zoomInUp hidden-xs" data-wow-delay="0.5s">DESTAQUES</h2>
                                            )
                                            : null
                                        }
                                        {      
                                            instagramData !== undefined && instagramData.length > 0 ? 
                                            ( 
                                                Object.keys(instagramData)
                                                .map(key => {
                                                    
                                                    return (
                                                        instagramData[key].imagem_url !== null && instagramData[key].imagem_url !== '' ? 
                                                            <div key={key} className="row hidden-xs" >
                                                                <div className="col-lg-12" >
                                                                    <a href={ instagramData[key].imagem_url } target="_blank"  rel="noopener noreferrer">
                                                                        <img className='img-responsive img-instagram' src={ instagramData[key].imagem_url } alt='imagem instagram'/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        : null
                                                    )
                                                    
                                                })
                                            )
                                            : null
                                        }
                                    </aside>
                                </div>
                                <div className="col-sm-9 col-sm-pull-3">
                                    <div className="row">
                                        {
                                            Object.keys(veiculos)
                                            .map(key => {
                                                var classRow = "";
                                                if((key + 1) % 3 === 0){
                                                    classRow = "row";
                                                }
                                                return (
                                                    <div  key={key} className={ classRow }>
                                                        <div className="col-lg-4 col-md-4 col-xs-12" >
                                                            <div className="b-items__cell wow zoomInUp" data-wow-delay="0.5s">
                                                                <Link to={ "/veiculo/"+veiculos[key].id}>
                                                                <div className="b-items__cars-one-img">
                                                                {
                                                                    veiculos[key].imagens.length > 0 ?
                                                                        veiculos[key].imagens[0].fileThumb !== undefined ?
                                                                            <img className='img-responsive' src={ veiculos[key].imagens[0].fileThumb } alt='imagem veículo'/>
                                                                            :
                                                                            <img className='img-responsive' src={ veiculos[key].imagens[0].fileUrl } alt='imagem veículo'/>
                                                                    : <img className='img-responsive' src="/img/car_default.png" alt='imagem veículo'/>
                                                                }
                                                                </div>
                                                                </Link>
                                                                <div className="b-items__cell-info">
                                                                    <Link to={ "/veiculo/"+veiculos[key].id}>
                                                                    <div className="s-lineDownLeft b-items__cell-info-title">
                                                                        <h2 className="">{ veiculos[key].vefipe_marca } { veiculos[key].vefipe_name }</h2>
                                                                    </div>
                                                                    </Link>
                                                                    <p>{ veiculos[key].veobservacao }</p>
                                                                    <div className="row m-smallPadding">
                                                                        <div className="col-xs-6">
                                                                            <ul>
                                                                                <li>{ this.state.veiculos[key].veano_fabricacao }/{ this.state.veiculos[key].vefipe_ano_modelo }</li>
                                                                                <li>{ veiculos[key].vefipe_combustivel }</li>
                                                                                <li>{ formatCambio(veiculos[key].vecambio) }</li>
                                                                                <li>{ veiculos[key].veportas } portas</li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="col-xs-6">
                                                                            <div className="b-items__cell-info-km">
                                                                                <span className="fa fa-tachometer"></span>
                                                                                <p>{ veiculos[key].vequilometragem } KM</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row m-smallPadding">
                                                                        <div className="col-xs-12">
                                                                            <h5 className="b-items__cell-info-price">{ formatMoney(veiculos[key].vevalor) }</h5>
                                                                        </div>
                                                                        <div className="col-xs-12">
                                                                            <Link to={ "/veiculo/"+veiculos[key].id} className="btn m-btn" >VER DETALHES<span className="fa fa-angle-right"></span></Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Rodape />
            </div>
        )
    }
}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value){
    if(value === 'A'){
        return "Automático";
    }else{
        return "Manual";
    }
}

export default Inicio