import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import *  as content from '../content-site';


class Rodape extends Component {

    data_atual = new Date();

    render() {
        return (

            <div>
                <div className="b-info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-xs-6">
                                <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                    <p>SOBRE NÓS</p>
                                    <div className="b-info__contacts-item">
                                        <em>{ content.texto_sobre_nos }</em>
                                    </div>
                                </address>
                            </div>
                            <div className="col-md-3 col-xs-6">
                                <div className="b-info__latest">
                                    <h3>ATENDIMENTO</h3>
                                    <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                        <div className="b-info__contacts-item">
                                            <em>Seg-Sex : { content.horario_seg_sex }<br />
                                                Sab : { content.horario_sab }</em>
                                        </div>
                                    </address>
                                </div>
                            </div>
                            <div className="col-md-3 col-xs-6">
                                <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                    <p>FALE CONOSCO</p>
                                    <div className="b-info__contacts-item">
                                        <span className="fa fa-map-marker"></span>
                                        <em> { content.endereco }<br/>
                                             { content.cidade }</em>
                                    </div>
                                    <div className="b-info__contacts-item">
                                        <span className="fa fa-phone"></span>
                                        <em>Telefone:  <a href={ "tel:" + content.telefone_link } >{ content.telefone }</a></em>
                                    </div>
                                    <div className="b-info__contacts-item">
                                        <span className="fa fa-whatsapp"></span>
                                        <em>Whatsapp:  <a href={ content.whatsapp_link }>{ content.whatsapp }</a></em>
                                    </div>
                                    <div className="b-info__contacts-item">
                                        <span className="fa fa-envelope"></span>
                                        <em>E-mail:  <a href={ "mailto:" + content.email }>{ content.email }</a></em>
                                    </div>
                                </address>
                            </div>
                            <div className="col-md-3 col-xs-6">
                                <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                    <p>VENHA NOS VISITAR</p>
                                    <a target="_blank" href={ content.mapa_link } className="wow slideInLeft" rel="noopener noreferrer" >
                                        <img src="/img/mapa.png" height="140px" alt={ "Mapa " + content.nome_revenda } />
                                    </a>
                                </address>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="b-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-4">
                                <div className="b-footer__company wow fadeInLeft" data-wow-delay="0.3s">
                                    <div className="">
                                        <img src="/img/logo_bottom.png" alt={ "Logotipo " + content.nome_revenda } style={{ height: "60px" }} />
                                    </div>
                                    <p>
                                        { this.data_atual.getFullYear() } Todos os direitos reservados. { content.nome_revenda }.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xs-8">
                                <div className="b-footer__content wow fadeInRight" data-wow-delay="0.3s" style={{ marginRight: "40px" }}>
                                    <div className="b-footer__content-social">
                                        <a href={ content.facebook_link } target="_blank" rel="noopener noreferrer"><span className="fa fa-facebook-square"></span></a>
                                        <a href={ content.instagram_link } target="_blank" rel="noopener noreferrer"><span className="fa fa-instagram" target="_blank"></span></a>
                                        <a href={ content.whatsapp_link } target="_blank" rel="noopener noreferrer" className="botao-whatsapp">
                                            <i className="fa fa-whatsapp"></i>
                                        </a>
                                    </div>
                                    <nav className="b-footer__content-nav">
                                        <ul>
                                            <li><Link to="/" > Início</Link></li>
                                            <li><Link to="/venda-seu-veiculo" > Venda seu veículo</Link></li>
                                            <li><Link to="/financiamento" > Financiamento</Link></li>
                                            <li><Link to="/contato" > Contato</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }

}

export default Rodape