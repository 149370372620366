// alterar arquivo: public/index.html Ok
// substituir imagens: public/img
// substituir favicon: /public/favicon.ico
// checar se existe código do google analytics (apagar ou substituir se for o caso)

// alterar arquivos: .firebaserc (conferir nesse se o default e target apontam para o projeto correto do firebase), firebase.json, package.json e info.txt com o nome do projeto no firebase

// color primary: public/assets/switcher/css/color2.css
// color secondary: public/assets/switcher/css/color2.css: 135

export const nome_revenda = "Márcio Automóveis";
export const url_revenda  = "https://marcioautomoveisptc.com.br/";
export const id_firebase  = "W5aJ8PN8HBmBmzaZ92W5";
export const texto_sobre_nos = "Veículos novos e seminovos com procedência e qualidade, nosso objetivo é alcançar a satisfação do cliente com veículos selecionados, bom atendimento e taxas de financiamento competitivas faça um visita a nossa loja e conheça nosso amplo estoque.";
export const horario_seg_sex = "8:00 - 18:00";
export const horario_sab = "8:00 - 13:00";
export const endereco = "Avenida General Astolfo Ferreira Mendes, 191 - Morada do Sol";
export const cidade = "Patrocínio - MG";
export const telefone = "(34) 3831-8794";
export const telefone_link = "3438318794";
export const whatsapp = "(34) 99196-1384";
export const whatsapp_link = "https://api.whatsapp.com/send?phone=5534991961384";
export const email = "amnromao@hotmail.com";
export const mapa_link = "https://www.google.com/maps/place/Av.+Gen.+Astolfo+Ferreira+Mendes,+191+-+Morada+do+Sol,+Patroc%C3%ADnio+-+MG,+38744-578/@-18.9335869,-46.9880367,17z/data=!3m1!4b1!4m5!3m4!1s0x94afba84707b40ab:0x1c8d7bbc77653344!8m2!3d-18.933592!4d-46.985848";
export const instagram_link = "https://www.instagram.com/automoveis_marcio/";
export const facebook_link = "https://www.facebook.com/Marcio-Autom%C3%B3veis-109066041417126";
